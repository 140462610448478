$theme-colors: (
  "brand": #e5873b,
  "acme": #cd5241,
  "primary": #2aa7a7,
);

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir-black.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/raleway-black.ttf") format("truetype");
}

.auth-login-qr-box canvas {
  height: 100% !important;
  width: 100% !important;
}

body {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 100vh !important;
}

body,
pre {
  font-family: "Raleway" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a.h1,
a.h2,
a.h3,
a.h4,
a.h5,
a.h6,
a.nav-link {
  font-family: "Avenir" !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.border-info-1 {
  border-left: 5px solid #0dcaf0 !important;
}

#userManagementContainer .card {
  margin-bottom: 1.5rem;
}

#userManagementContainer .user-picture {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 50px !important;
}

.widgets-icons-2 {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  font-size: 16px;
  border-radius: 10px;
}

#add-user-form label.required:after {
  content: " *";
  color: red;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.request-action-btn-box {
  button {
    border-radius: 20px;
    font-weight: bold;
  }

  .approved {
    background-color: #c1f0ce;
    color: #08aa64 !important;

    i {
      background-color: #08aa64;
      padding: 6px;
      border-radius: 100%;
      color: #fff;
    }
  }

  .rejected {
    background-color: #f4b9c4;
    color: #d11439 !important;

    i {
      background-color: #d11439;
      padding: 6px 8px;
      border-radius: 100%;
      color: #fff;
    }
  }

  .view-detail {
    background-color: #a5d9ef;
    color: #0c75a1 !important;

    i {
      background-color: #0c75a1;
      padding: 6px 10px;
      border-radius: 100%;
      color: #fff;
    }
  }
}

.my-checkbox {
  transform: scale(1.5);
}

.requests-table {
  tbody {
    th,
    td {
      vertical-align: middle;
    }
  }

  thead {
    background-color: lightgray;
  }
}

.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #6074e4 !important;
  border-color: #6074e4 #6074e4 #6074e4 !important;
}

.refresh {
  cursor: pointer;
}

.prompt-card-body {
  padding: 1rem !important;
  overflow: scroll;
  height: 500px;
  scrollbar-width: none;
  // min-height: 400px !important;
  // overflow-y: scroll;
}

.prompt-text {
  // display: inline-block;
  // max-width: 100%;
  // padding: 20px;
  // border: 1px solid #ccc;
  // background-color: #f0f0f0;
  // word-wrap: break-word;
  // white-space: pre-wrap;
  // margin: 0;

  white-space: pre-wrap;
  // overflow: scroll;
  // height: 300px;
  scrollbar-width: none;
  // font-size: 16px;
  font-weight: 400;
}

// AUTOCOMPLETE #########################

[role="option"]:hover {
  background: #ddddff;
  color: #000;
  border-color: #999;
  cursor: pointer;
}

.suggestions {
  position: relative;
}

[role="listbox"] {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  overflow-x: scroll;
}

[role="listbox"]::-webkit-scrollbar {
  display: none;
}

[role="option"] {
  padding: 0.25em;
  color: #999;
  border: 1px solid lightgray;
  border-top: 0;
}

.hyperlink {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

// AUTOCOMPLETE #########################

// MEDIA QUERY #########################

// FOR MOBILE
@media screen and (max-width: 426px) {
  .my-xs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

// FOR TABLET
@media (min-width: 427px) and (max-width: 768px) {
  #userManagementContainer .card-body h3 {
    font-size: 0.7rem !important;
  }

  #userManagementContainer .card-body h5 {
    font-size: 0.6rem !important;
  }

  .d-block-tablet {
    width: 100% !important;
    max-width: 100% !important;
    flex: 100% !important;
  }
}
